<template>
  <div>
    <div>
      <p v-html="$t('onboarding.classification.acknowledgement.question')"></p>
      <div class="document-container" v-if="document">
        <DownloadDocument
          :document="document"
          :templateId="prokDocuments.key"
          maxWidth="auto"
        />
      </div>
      <Spinner
        v-else
        :size="30"
      />
      <v-form v-if="!settings">
        <v-radio-group
          v-model="data.classification.acknowledgement.optInForProfessional"
        >
          <v-radio
            v-for="(acknowledgement, index) in acknowledgements"
            :key="index"
            :label="$t(`onboarding.classification.acknowledgement.answer.${acknowledgement.text}`)"
            :value="acknowledgement.value"
            class="mt-2"
          ></v-radio>
        </v-radio-group>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import clone from '@/utils/clone';
import { investorClassification } from '@/utils/validators';
import Spinner from '@/components/Shared/Spinner.vue';

export default {
  name: 'Acknowledgement',
  components: {
    DownloadDocument: lazyLoad('components/Documents/DownloadDocument'),
    Spinner,
  },
  props: {
    settings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        investorClassification,
      },
      acknowledgements: [
        {
          text: 'no',
          value: false,
        },
        {
          text: 'yes',
          value: true,
        },
      ],
      prokDocuments: null,
    };
  },
  async created() {
    this.prokDocuments = await this.$store.dispatch('documents/getDocumentTemplate', {
      documentTemplateId: 'consent_declaration_prok',
    });
    this.setFormValidity(true);
  },
  computed: {
    document() {
      return this.prokDocuments ? this.prokDocuments.documents.find((item) => item.key === 'information_prok.pdf') : null;
    },
  },
  methods: {
    ...mapActions('onboarding', [
      'setFormValidity',
    ]),
    ...mapActions('user', [
      'updateUser',
      'updateOnboarding',
      'setMainStage',
      'setSubStage',
    ]),
    ...mapActions('settings', [
      'nextInvestorClassificationStep',
      'setClassificationAcknowledgement',
    ]),
    async submit() {
      const input = {
        investor: {
          classification: {
            acknowledgement: this.data.classification.acknowledgement,
          },
        },
        mainStage: 'classification',
        subStage: 'acknowledgement',
      };
      try {
        const nextStage = await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (nextStage.nextMainStage === 'videoident') {
          this.setSubStage('information');
          this.setMainStage('videoident');
          this.$router.push({
            name: 'Onboarding.Overview',
          });
        } else {
          this.setSubStage('conditions');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
    edit() {
      this.setClassificationAcknowledgement({
        optInForProfessional: true,
      });
      this.nextInvestorClassificationStep();
    },
  },
};
</script>

<style lang="scss" scoped>
.document-container {
  width: 420px;
}
@media (max-width: 456px) {
  .document-container {
    width: 100% !important;
  }
}
</style>
