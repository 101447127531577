<template>
  <div :class="{'loading-overlay': overlay}">
    <v-progress-circular
      indeterminate
      color="primary"
      :size="size"
      :width="5"
    />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 50,
    },
  },
};
</script>

<style scoped lang="scss">
.loading-overlay {
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
